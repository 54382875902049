import './welcome.css';

function Welcome() {
  return (
    <div className="emoji-game-welcome">
      <br />
      <h1>Hi</h1>

      <div className="emoji-game-typewriter ">
        <h1>Welcome to the EMOJI </h1>
        <h1>guess game</h1>
      </div>
    </div>
  );
}

export default Welcome;
