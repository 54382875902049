const GalleryAPI = [
  {
    id: 1,
    title: 'The Wave – Just Out Of A Painting',
    discription:
      'The Wave is a sandstone rock formation located in Arizona, United States, near its northern border with Utah',
    statement: 'Know More',
    url: 'https://media.gettyimages.com/photos/hikers-among-the-rock-formations-at-the-wave-in-the-coyote-buttes-of-picture-id564033857?k=20&m=564033857&s=612x612&w=0&h=WE9604UGHLn2gLHYI9hS-Z8WHCczfslTbXSWIubuNNw=',
    videoUrl:
      'https://media.istockphoto.com/videos/beautiful-narrow-canyon-with-red-smooth-and-wavy-rocks-carved-by-video-id1130468780?s=256_srp_is'
  },
  {
    id: 2,
    title: 'Chocolate Hills',
    discription:
      'The Chocolate Hills are a geological formation in the Bohol province of the Philippines.',
    statement: 'Get Ideas',
    url: 'https://media.gettyimages.com/photos/dramatic-light-over-chocolate-hills-bohol-philippines-picture-id1203882849?k=20&m=1203882849&s=612x612&w=0&h=X-LrgUvRrRG6pa76ZIprydejfzrVe6cDs4PMq0yb2KY=',
    videoUrl: 'https://media.istockphoto.com/videos/corn-and-trees-video-id1163182215'
  },
  {
    id: 3,
    title: 'sea of stars',
    discription:
      'It is the Late-summer site with a unique phenomenon of sparkling waters due to bioluminescent phytoplankton',
    statement: 'Join Now',
    url: 'https://img.atlasobscura.com/-714kRa70D74swzWNha3DgImXZho3G3Bnv3o5jaBEFo/rs:fill:780:520:1/g:ce/q:81/sm:1/scp:1/ar:1/aHR0cHM6Ly9hdGxh/cy1kZXYuczMuYW1h/em9uYXdzLmNvbS91/cGxvYWRzL3BsYWNl/X2ltYWdlcy85NWQ5/ZmE0OC0zYWE4LTRl/MTYtYmNjYS05Zjdi/YTNkNDk1MDFkZTRj/ZThmYzFlZWNhZmQy/OWJfRTQ2MUVCLmpw/Zw.jpg',
    videoUrl:
      'https://media.istockphoto.com/videos/milky-way-galaxy-over-scenic-mountain-and-lake-time-lapse-video-video-id945671798'
  },
  {
    id: 4,
    title: 'Nelson Lakes National Park',
    discription:
      'Nelson Lakes National Park is in the South Island of New Zealand, at the northern end of the Southern Alps.',
    statement: 'Create Now',
    url: 'https://media.gettyimages.com/photos/jetty-lake-rotoiti-nelson-lakes-national-park-nz-picture-id164537233?k=20&m=164537233&s=612x612&w=0&h=yfBC5Jzb1R8Q9LRIBJpC44R8Oc21C8YWikm0hJlXdWk=',
    videoUrl: 'https://media.istockphoto.com/videos/following-the-boat-video-id943642378'
  },
  {
    id: 5,
    title: 'Ada Lovelace',
    discription: 'Ada Lovelace is considered the first computer programmer',
    url: 'https://media.gettyimages.com/photos/augusta-ada-countess-lovelace-1st-wife-of-william-king-the-first-earl-picture-id923548586?k=20&m=923548586&s=612x612&w=0&h=uRufeV4sEOAlsSRb4vWHfMU7YExe54yGMaHTtEEEuqY='
  },
  {
    id: 6,
    title: 'Banff National Park, Canada',
    discription: "Canada's first national park and the world's third, it has a rich heritage.",
    url: 'https://media.istockphoto.com/photos/moraine-lake-in-banff-national-park-canada-picture-id500177214?b=1&k=20&m=500177214&s=170667a&w=0&h=EPOxBFVlZzBu0HmUNERPHLEcAyeFyaNj2NcJZzmBNZo=',
    videoUrl:
      'https://media.istockphoto.com/videos/lake-louise-in-banff-national-park-canada-time-lapse-video-id1324493205'
  },
  {
    id: 7,
    title: 'Mississippi River',
    discription: "The Mississippi River is one of the world's most important commercial waterways",
    url: 'https://images.unsplash.com/photo-1599829680246-e852638b30b4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWlzc2lzc2lwcGklMjByaXZlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    videoUrl: 'https://media.istockphoto.com/videos/river-at-sunset-video-id1019777896'
  },
  {
    id: 8,
    title: 'The Nile',
    discription: 'The Nile is a major north-flowing river in northeastern Africa',
    url: 'https://media.gettyimages.com/photos/aswan-egypt-picture-id157643730?k=20&m=157643730&s=612x612&w=0&h=UcbCKcLrAbyA4RWSIvoff49wjQmFM-HHq8L8Gzmi_GQ=',
    videoUrl: 'https://media.istockphoto.com/videos/cairo-video-id668180358'
  },
  {
    id: 9,
    title: 'Caño Cristales',
    discription:
      'It is also known as River of Gods, The Rainbow River, or The Most Beautiful River in the World',
    url: 'https://thumbs.dreamstime.com/b/most-picturesque-multi-colored-river-world-cano-cristales-serrania-de-la-macarena-national-park-colombia-colorful-195759565.jpg',
    videoUrl:
      'https://previews.customer.envatousercontent.com/files/74ed94ea-dc37-483d-8f1e-2284fb67c386/video_preview_h264.mp4'
  },
  {
    id: 10,
    title: 'Norway',
    discription:
      'Norway is known as the Land of the Midnight Sun. It is famous for its phenomenal fjords, lakes, and magical skies',
    url: 'https://images.unsplash.com/photo-1520769669658-f07657f5a307?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bm9yd2F5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    videoUrl:
      'https://player.vimeo.com/external/438212462.sd.mp4?s=662827a4f8a2ef58bc4d0a39a02f9f945ada32e7&profile_id=164'
  },
  {
    id: 11,
    title: 'Uttarakhand',
    discription:
      "This one is valley of flowers, known for it's crawling medows and differnt spices of flowers. This is literally heaven in August.",
    url: 'https://cdn.pixabay.com/photo/2022/06/05/05/59/mussoorie-7243347_960_720.jpg',
    videoUrl:
      'https://player.vimeo.com/external/420605904.sd.mp4?s=834b3225bc4ff73f4af1b32f42483b63b0cea1be&profile_id=164&oauth2_token_id=57447761'
  },
  {
    id: 12,
    title: 'Red Beach',
    discription:
      'Get A Break From White Beaches. Red beach is famed for its red-hued sand which is one of the unique places to visit.',
    url: 'https://media.gettyimages.com/photos/aerial-view-of-red-beach-picture-id1348786264?k=20&m=1348786264&s=612x612&w=0&h=IadvbfZ0TWwh3ZZ9kWKLCw7kh-3KoPVgKmIFNBa79Cg=',
    videoUrl:
      'https://ak.picdn.net/shutterstock/videos/1065515356/preview/stock-footage-aerial-view-of-the-red-beach-national-scenic-corridor-wetland-in-panjin-city-of-china.webm'
  },
  {
    id: 13,
    title: 'Rainbow Mountain: China',
    discription:
      'These mountains were formed when a mixture of different coloured sandstone and minerals landed on each other over approximately 24 million years',
    url: 'https://media.gettyimages.com/photos/rainbow-mountains-zhangye-danxia-geopark-china-picture-id1136869431?k=20&m=1136869431&s=612x612&w=0&h=W1tMLEgEivU_DTUzjIBduk947BeHYQg4pF2JbVLJJHo=',
    videoUrl:
      'https://media.istockphoto.com/videos/aerial-landscape-of-rainbow-mountain-peak-at-vinicunca-valley-in-peru-video-id1400251538'
  },
  {
    id: 14,
    title: 'The Great Blue Hole ',
    discription: 'It is a giant marine sinkhole which is 318 m across and 124 m deep',
    url: 'https://media.gettyimages.com/photos/belize-blue-hole-from-the-air-picture-id88549170?k=20&m=88549170&s=612x612&w=0&h=5nuXc2RCHceMgHrAzA4vVKjGWcrgqb7zoZDqOosTwGk=',
    videoUrl:
      'https://media.istockphoto.com/videos/tourists-take-underwater-diving-in-blue-hole-palau-video-id914520864'
  },
  {
    id: 15,
    title: 'Plitvice Lakes, Croatia',
    discription:
      "It is a 295 square kilometer forest reserve in central Croatia. It's known for a chain of 16 terraced lakes",
    url: 'https://images.unsplash.com/photo-1596097155664-4f5c49ba1b69?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGxpdHZpY2UlMjBsYWtlcyUyMG5hdGlvbmFsJTIwcGFya3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    videoUrl:
      'https://media.istockphoto.com/videos/scenic-view-of-waterfall-in-the-nature-of-plitvice-lakes-national-video-id1341933128'
  },
  {
    id: 16,
    title: 'Taj Mahal, India',
    discription:
      'The Taj Mahal, is an Islamic ivory-white marble mausoleum on the right bank of the river Yamuna in the Indian city of Agra',
    url: 'https://images.unsplash.com/photo-1564507592333-c60657eea523?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dGFqJTIwbWFoYWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    videoUrl:
      'https://media.istockphoto.com/videos/taj-mahal-intricate-details-of-landmark-mausoleum-tilt-down-video-id1324924133'
  },
  {
    id: 17,
    title: 'Arashiyama Bamboo Grove',
    discription:
      "Beautiful but often crowded, Japan's most famous bamboo forest. It's believed to be one of the most photographed places in Kyoto",
    url: 'https://www.busytourist.com/wp-content/uploads/2019/06/Arashiyama-Bamboo-Grove-of-Kyoto-Japan.jpg.webp',
    videoUrl:
      'https://media.istockphoto.com/videos/fresh-green-bamboo-swaying-in-the-wind-video-id1222488861'
  },
  {
    id: 18,
    title: 'Cappadocia, Turkey',
    discription:
      "It is Famous for its unique rock formations and amazing hot air ballooning opportunities. one of Turkey's most popular natural wonders",
    url: 'https://images.unsplash.com/photo-1604156789095-3348604c0f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2FwcGFkb2NpYSUyMHR1cmtleXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    videoUrl:
      'https://media.istockphoto.com/videos/female-traveler-with-backpack-looking-to-the-air-baloons-sporty-girl-video-id1189114935'
  },
  {
    id: 19,
    title: 'Sandakphu, Darjeeling',
    discription:
      'Sandakphu, one of the most dangerous offbeat places in India, is the highest peak in Darjeeling',
    url: 'https://assets.traveltriangle.com/blog/wp-content/uploads/2015/11/Sandakphu-in-Darjeeling.jpg',
    videoUrl:
      'https://media.istockphoto.com/videos/fresh-green-tea-leaves-on-the-tea-bush-close-up-video-id1388114232'
  },
  {
    id: 20,
    title: 'Statue Of Unity ',
    discription:
      "The Statue of Unity is the world's tallest statue, with a height of 182 metres, located in the state of Gujarat, India",
    url: 'https://cdn.pixabay.com/photo/2021/10/03/14/34/statue-6678155__340.jpg',
    videoUrl:
      'https://ak.picdn.net/shutterstock/videos/1024024013/preview/stock-footage-statue-of-unity.webm'
  },
  {
    id: 21,
    title: 'Yellowstone National Park, USA',
    discription:
      'Nature is amazing. It is the combination of mountains, forests, waterfalls, hot springs, and wildlife is breath-taking',
    url: 'https://images.unsplash.com/photo-1629985692757-48648f4f1fc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8eWVsbG93c3RvbmUlMjBuYXRpb25hbCUyMHBhcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    videoUrl:
      'https://media.istockphoto.com/videos/grand-prismatic-spring-overlook-yellowstone-national-park-wyoming-usa-video-id1336259333'
  },
  {
    id: 22,
    title: 'Rainbows, Hawaii',
    discription: 'Hawaii is the best place in the world to see rainbows.',
    url: 'https://images.unsplash.com/photo-1643483698945-a5b5f5209fc4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    videoUrl:
      'https://media.istockphoto.com/videos/rainbow-over-a-beach-with-lava-rocks-in-hawaii-video-id494202142'
  },
  {
    id: 23,
    title: 'Longest Name, New Zeland',
    discription:
      'The longest place name on the planet is 85 letters long. This place is in New Zeland.',
    url: 'https://c8.alamy.com/comp/EMJ84W/taumatawhakatangihangakoauauotamateaturipukakapikimaungahoronukupokaiwhenuakitanatahu-EMJ84W.jpg',
    videoUrl:
      'https://media.istockphoto.com/videos/new-zealand-countryside-at-opposite-lighting-video-id1407679929'
  },
  {
    id: 24,
    title: '421 Snow Word, Scotland',
    discription: 'Scotland has 421 words for snow. like: sneesl, feefle, flinkdrinkin, etc.',
    url: 'https://www.rd.com/wp-content/uploads/2018/02/07-Incredible-Facts-About-Practically-Everything.jpg?resize=768,512',
    videoUrl:
      'https://media.istockphoto.com/videos/journey-through-the-mountain-coniferous-forest-in-winter-gopro-video-id508775038'
  }
];

export default GalleryAPI;
