import React from 'react';
import { FaMedapps } from 'react-icons/fa';

function Header() {
  return (
    <>
      <header className="head">
        <h1 className="headsHeading">
          {' '}
          <FaMedapps /> KEEPER{' '}
        </h1>
      </header>
    </>
  );
}

export default Header;
