import React from 'react';

const TextImage = ({ TextfromImage }) => {
  return (
    <div style={{ margin: '25px 25px' }}>
      <p>{TextfromImage}</p>
    </div>
  );
};

export default TextImage;
