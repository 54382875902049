const Recipes = [
  {
    recipe: {
      url: 'https://www.awesomecuisine.com/wp-content/uploads/2014/03/chicken-tikka.jpg',
      title: 'Chicken Tikka',
      vegan: 'non-veg',
      mealtype: 'snacks',
      cusine: 'Indian',
      price: '20',
      randomfact: 'India is the Spice Capital of the World'
    }
  },
  {
    recipe: {
      url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Biryani_of_Lahore.jpg/640px-Biryani_of_Lahore.jpg',
      title: 'Biryani',
      vegan: 'veg',
      mealtype: 'lunch',
      cusine: 'Indian',
      price: '15',
      randomfact:
        'As per Ayurveda, India’s ancient medicinal system, there are three primary categories of food – Satvic, Rajasic and Tamasic.'
    }
  },
  {
    recipe: {
      url: 'https://bigoven-res.cloudinary.com/image/upload/t_recipe-256/dal-makhani-how-to-make-dal-makhani-2258046.jpg',
      title: 'Dal Makhani',
      vegan: 'veg',
      mealtype: 'Dinner',
      cusine: 'Indian',
      price: '12',
      randomfact:
        'India is home to bhot jolokia, one of the hottest chillies in the world. Also called “ghost chilli”, '
    }
  },
  {
    recipe: {
      url: 'https://food.fnr.sndimg.com/content/dam/images/food/fullset/2019/10/9/DV3016_masala-dosa_s4x3.jpg.rend.hgtvcom.406.305.suffix/1570635680579.jpeg',
      title: 'Dosha',
      vegan: 'veg',
      mealtype: 'Breakfast',
      cusine: 'Indian',
      price: '16',
      randomfact: 'Indian people use their hands to eat'
    }
  },
  {
    recipe: {
      url: 'https://imagesvc.meredithcorp.io/v3/mm/image?q=60&c=sc&poi=face&w=1600&h=800&url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F43%2F2020%2F09%2F21%2F290890_original.jpg',
      title: 'Kung Pao Chicken',
      vegan: 'Non-veg',
      mealtype: 'Lunch',
      cusine: 'Chinese',
      price: '14',
      randomfact: 'China is the homeland of tea.'
    }
  },
  {
    recipe: {
      url: 'https://previews.123rf.com/images/iphotoshot/iphotoshot1203/iphotoshot120300010/12882609-disk-of-soybean-milk-and-deep-fried-dough-stick-for-breakfast.jpg',
      title: 'Soybean Milk and Deep-Fried Dough Sticks',
      vegan: 'veg',
      mealtype: 'Lunch',
      cusine: 'Chinese',
      price: '10',
      randomfact:
        "Chinese don't eat with knives and forks, traditionally, which is seen as violent or barbaric, but with chopsticks"
    }
  },
  {
    recipe: {
      url: 'https://mamalovestocook.com/wp-content/uploads/2020/05/boiled-dumplings-480x480.jpg',
      title: 'dumplings',
      vegan: 'Non-veg',
      mealtype: 'Dinner',
      cusine: 'Chinese',
      price: '18',
      randomfact:
        'Fortune Cookie was invented in California, as a variation of Japanese “omikuji senbei” fortune cookies. The main difference of Fortune Cookies is that they are much sweeter than Japanese variant.'
    }
  },
  {
    recipe: {
      url: 'http://www.ecns.cn/2016/03-16/U472P886T1D203169F12DT20160316132624.jpg',
      title: 'Latito',
      vegan: 'veg',
      mealtype: 'snack',
      cusine: 'Chinese',
      price: '8',
      randomfact: 'Ancient Chinese were very interested in food preservation methods'
    }
  },
  {
    recipe: {
      url: 'https://ca-times.brightspotcdn.com/dims4/default/fbdb510/2147483647/strip/true/crop/1154x721+63+0/resize/1044x652!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F62%2F5d%2Ffe1b17ccdd32e48540720225cc19%2Fkikyd4nc-recipe-db',
      title: "Soupe à l'oignon",
      vegan: 'Non-veg',
      mealtype: 'breakfast',
      cusine: 'French',
      price: '18',
      randomfact: 'French fries… are not French!'
    }
  },
  {
    recipe: {
      url: 'https://sf2.viepratique.fr/wp-content/uploads/sites/2/2013/11/blanquette-de-veau-e1457428254753.jpg',
      title: 'Blanquette De Veau ',
      vegan: 'Non-veg',
      mealtype: 'Lunch',
      cusine: 'French',
      price: '25',
      randomfact: 'Approximately 500,000,000 snails are eaten in France per year'
    }
  },
  {
    recipe: {
      url: 'https://www.curiouscuisiniere.com/wp-content/uploads/2015/10/French-Onion-Soup-2731.21.jpg.webp',
      title: 'Soupe À l’oignon',
      vegan: 'veg',
      mealtype: 'Dinner',
      cusine: 'French',
      price: '30',
      randomfact:
        'French people love alcohol so much that they drink some before and after the meal'
    }
  },
  {
    recipe: {
      url: 'https://www.yumcurry.com/wp-content/uploads/2020/06/pizza-margherita-recipe-500x375.jpg',
      title: 'margherita',
      vegan: 'Non-veg',
      mealtype: 'breakfast',
      cusine: 'Italian',
      price: '30',
      randomfact: 'Pizza was invented in Italy, in Naples'
    }
  },
  {
    recipe: {
      url: 'https://cdn.loveandlemons.com/wp-content/uploads/2021/11/minestrone-soup-500x500.jpg',
      title: 'Minestrone',
      vegan: 'Non-veg',
      mealtype: 'Dinner',
      cusine: 'Italian',
      price: '25',
      randomfact: 'An Italian eat an average of 23 kg (51 lbs) of pasta per year'
    }
  }
];

export default Recipes;
