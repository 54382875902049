import guide1 from '../assets/guide/guide-1.jpg';
import guide2 from '../assets/guide/guide-2.jpg';
import guide3 from '../assets/guide/guide-3.jpg';
import guide4 from '../assets/guide/guide-4.jpg';
import guide5 from '../assets/guide/guide-5.jpg';
import guide6 from '../assets/guide/guide-6.jpg';
import guide7 from '../assets/guide/guide-7.jpg';
import guide8 from '../assets/guide/guide-8.jpg';

export const guides = [guide1, guide2, guide3, guide4, guide5, guide6, guide7, guide8];
