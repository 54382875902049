import titleOne from './images/photo-1511028931355-082bb4781053.jpg';
import titleTwo from './images/photo-1559511331-7649f05944e3.jpg';
import titleThree from './images/photo-1608822246990-be7bc0d9b2d7.jpg';
import titleFour from './images/photo-1611773951628-0d5b4c2507fb.jpg';
import titleFive from './images/photo-1567117439290-a8b5ef5bab89.jpg';

const data = [
  {
    title: 'Title One',
    url: titleOne
  },
  {
    title: 'Title Two',
    url: titleTwo
  },
  {
    title: 'Title Three',
    url: titleThree
  },
  {
    title: 'Title Four',
    url: titleFour
  },

  {
    title: 'Title Five',
    url: titleFive
  }
];

export default data;
